import { Grade } from 'app/library/grading-scheme/models/grade.model';
import { Module } from 'app/library/module/models/module.model';
import { newModule } from 'app/library/module/models/newModule';
import { User } from 'app/projects/user/src/lib/models/user';
import { IFinalGradeQuery } from '../interfaces/final-grade.query.interface';

export class FinalGrade {
    static label_singular = 'final-grade';
    static label_plural = 'final-grades';

    static props_to_search = ['comment', 'user', ...User.props_to_search];

    id: string = null;
    comment: string = null;
    moduleId: string = null;
    module?: Module = null;
    moduleName?: string = null;
    user?: User = null;
    gradeId: string = null;
    grade?: Grade = null;
    status: string = 'published';
    averageScore: number;
    sumScore: number;
    certificate: {
        id: string;
        name: string;
    } = null;

    constructor(query?: IFinalGradeQuery, isFullModule: boolean = false) {
        if (query) {
            if (query.id) {
                this.id = query.id.toString();
            }
            this.comment = query.comment;
            if (query.module && query.module.id) {
                this.moduleId = query.module.id.toString();
            }
            if (query.module && query.module.name) {
                this.moduleName = query.module.name;
            }
            if (isFullModule) {
                this.module = newModule(query.module);
            }
            if (query.participant && query.participant.id) {
                this.user = new User(query.participant);
            }
            if (query.grade && query.grade.id) {
                this.gradeId = query.grade.id.toString();
                this.grade = new Grade(query.grade);
            }
            this.status = query.status;
            this.averageScore = query.average_score;
            this.sumScore = query.sum;
            if (query.certificate && query.certificate.id) {
                this.certificate = {
                    id: query.certificate.id.toString(),
                    name: query.certificate.name,
                };
            }
        }
    }

    get userFullName(): string {
        return this.user ? this.user.fullName : '';
    }

    get published(): boolean {
        return this.status === 'published';
    }
}
