<div fxLayout="row" fxLayoutAlign="space-between" (click)="onClickButton($event)" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
    <span fxLayout="row" fxLayoutAlign="start center" class="pr-16 rounded-full pointer max-w-full" [ngClass]="isHovered ? bgHoverColor : bgColor">
        <span fxLayoutAlign="center center" class="oval rounded-full min-w-9 min-h-9">
            <mat-icon svgIcon="certificate"></mat-icon>
        </span>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="text-gray-700 text-base sm:text-sm line-clamp-1 mx-8" tabindex="0">
                {{ label }}
            </span>
            <mat-icon svgIcon="download"></mat-icon>
        </div>
    </span>
</div>
