import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../../projects/core/src/lib/models/unsubscribe-on-destroy';
import { ContentNode } from '../../models/content-node.model';
import { ContentNodeService } from '../../services/content-node.service';

@Component({
    selector: 'app-progress-drawer',
    templateUrl: './progress-drawer.component.html',
    styleUrls: ['./progress-drawer.component.scss'],
})
export class ProgressDrawerComponent extends UnsubscribeOnDestroy implements OnInit {
    @HostBinding('class.no-uploads')
    isHidden = true;

    parentNode: ContentNode;

    expanded: boolean;
    isKmpAppActive: boolean = false;

    get inProgressNodeKeys(): string[] {
        if (!this.parentNode) {
            return [];
        }

        return this.contentNodeService.inProgressNodeKeys(this.parentNode.id);
    }

    get successNodes(): {
        parentNodeId: string;
        name: string;
    }[] {
        if (!this.parentNode) {
            return [];
        }

        return this.contentNodeService.filterSuccessNodes(this.parentNode.id);
    }

    get failureNodes(): { node: string; file: string }[] {
        if (!this.parentNode) {
            return [];
        }

        return this.contentNodeService.filterFailureNodes(this.parentNode.id);
    }

    get isVisible(): boolean {
        const value = (this.inProgressNodeKeys.length || this.failureNodes.length || this.successNodes.length) && !this.isKmpAppActive;

        this.isHidden = !value;

        return !this.isHidden;
    }

    constructor(public contentNodeService: ContentNodeService, private changeDetection: ChangeDetectorRef) {
        super();

        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }
    }

    ngOnInit(): void {
        this.contentNodeService.inProgressNode$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.changeDetection.detectChanges();
        });

        this.contentNodeService.visibility$
            .pipe(
                filter(({ node }) => this.parentNode && this.parentNode.id === node),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(({ visible }) => {
                this.isHidden = !visible;
            });

        this.contentNodeService.parentNode$.pipe(takeUntil(this._unsubscribeAll)).subscribe((node) => {
            this.expanded = !node ? false : this.contentNodeService.isExtended(node.id);
            this.parentNode = node;
        });
    }

    onExpandedChange(value: boolean): void {
        this.contentNodeService.updateIsExtended(this.parentNode.id, value);
        this.expanded = this.contentNodeService.isExtended(this.parentNode.id);
    }

    removeFiles(): void {
        this.onExpandedChange(false);
        this.contentNodeService.removeFinishedNodes(this.parentNode.id);
    }
}
