import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
    @Input()
    title: string = '';

    @Input()
    additionalText?: string = '';

    @Input()
    closeIcon: string = 'close';

    @Output()
    onClick = new EventEmitter<any>();

    constructor() {}

    onClickButton(event): void {
        this.onClick.emit(event);
    }
}
