import * as moment from 'moment';
import { IUserLearningProgressQuery } from '../interfaces/user-learning-progress.query.interface';
import { User } from './user';

export class UserLearningProgress {
    user: User;
    totalLearningTime: string;
    notStartedCourses: number;
    inProgressCourses: number;
    completedCourses: number;

    constructor(query: IUserLearningProgressQuery) {
        this.user = new User(query.user);
        this.totalLearningTime = moment.duration(query.total_learning_time).asMinutes().toFixed(0);
        this.notStartedCourses = query.not_started_courses;
        this.inProgressCourses = query.in_progress_courses;
        this.completedCourses = query.completed_courses;
    }
}
