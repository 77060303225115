import { CertificateRecord } from 'app/library/certificate-record/models/certificate-record';
import { CertificationProvider } from 'app/library/certification-provider/models/certification-provider';
import { CertificationProviderStatusEnum } from '../../certification-provider/enums/certification-provider-status.enum';
import { ICertificationQuery } from '../interfaces/certification.query.interface';

export class Certification {
    id: string;
    certificationName: string;
    status: CertificationProviderStatusEnum;
    validityPeriod: number;
    certificates?: CertificateRecord[];
    provider?: CertificationProvider;
    isAddedToModule?: boolean;
    certificateTemplate?: {
        templateName: string;
        filename: string;
        id: string;
        isPublic: boolean;
        name: string;
        type: string;
    };

    constructor(query?: ICertificationQuery) {
        if (query) {
            this.id = query?.id?.toString();
            this.certificationName = query?.name;
            this.status = query?.status;
            this.validityPeriod = query?.validity_period;
            if (query.certificates?.length) {
                this.certificates = query.certificates.map((certificate) => new CertificateRecord(certificate));
            }
            if (query?.provider) {
                this.provider = new CertificationProvider(query.provider);
            }
            this.isAddedToModule = query?.is_added_to_module;
            if (query?.certificate_template) {
                this.certificateTemplate = {
                    templateName: query.certificate_template.certificate_name,
                    filename: query.certificate_template.filename,
                    id: query.certificate_template.id.toString(),
                    isPublic: query.certificate_template.is_public,
                    name: query.certificate_template.name,
                    type: query.certificate_template.type,
                };
            }
        } else {
            this.id = null;
            this.certificationName = null;
            this.status = null;
            this.validityPeriod = null;
            this.certificates = null;
            this.provider = null;
            this.certificateTemplate = null;
        }
    }
}
