import { Certification } from '../../certifications/models/certification';
import { ICertificationProviderQuery } from '../interfaces/certification-provider.query.interface';

export class CertificationProvider {
    id: string;
    providerName: string;
    type: number;
    status: number;
    certifications?: Certification[];

    constructor(query: ICertificationProviderQuery) {
        this.id = query.id.toString();
        this.providerName = query.name;
        this.type = query.type;
        this.status = query.status;
        if (query.certifications?.length) {
            this.certifications = query.certifications.map((certification) => new Certification(certification));
        }
    }
}
